<template>
    <div class="card" style="width: 100%;border-color: #4d5a70 !important;">
        <div
            class="card-header"
            :style="
                'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
            "
        >
            <span>{{$t('label.TypeOfContainer')}}</span>
        </div>
        <div class="card-body">
            <CRow style="width: 100%;">
                <CCol style="padding-right:0px;">
                    <CCol lg="12" style="height : 95px;padding-right:0px">
                    </CCol>
                    <CCol lg="12" style="padding-right:0px;">
                        <div class="card-tipo-cantidades">
                            <div class="movs-labels">
                                <h5 class="estandar-letra"><span>{{$t('label.DISCHARGE')}}</span></h5>
                            </div>
                            <div class="movs-labels" style="margin-top:15px;">
                                <h5 class="estandar-letra"><span>{{$t('label.LOAD')}}</span></h5>
                            </div>
                            <div class="movs-labels" style="margin-top:15px;">
                                <h5 class="estandar-letra"><span>{{$t('label.shipToShip')}}</span></h5>
                            </div>
                            <div class="movs-labels" style="margin-top:15px;">
                                <h5 class="estandar-letra"><span>{{$t('label.shipLandShip')}}</span></h5>
                            </div>
                        </div>
                    </CCol>
                </CCol>
                <CCol v-for="(item, index) in infoArray" :key="index">
                    <CRow>
                        <CCol lg="12">
                            <div class="card-tipo-titulo d-flex align-items-center" :style="'background-color:'+item.color">
                                <div class="flex-fill"><img class="img-fluid rounded" height="40px" width="40px" :src="routeDoc(item)" @error="setAltImg" alt="Card image cap"></div>
                                <div class="flex-fill d-flex align-content-center estandar-letra" >
                                    {{item.title}}
                                </div>
                            </div>
                        </CCol>
                        <CCol lg="12">
                            <div class="w-100" style="margin-top:15px;padding-right: 2px;padding-left: 2px;">
                                <div class="row">
                                    <div class="col-sm" style="padding-right: 5px;padding-left: 5px;">
                                        <div class="tipoContenedor20">
                                            <h2 class="estandar-letra">20</h2>
                                        </div>
                                    </div>
                                    <div class="col-sm" style="padding-right: 5px;padding-left: 5px;"> 
                                        <div class="tipoContenedor40">
                                            <h2 class="estandar-letra">40</h2>
                                        </div>
                                    </div>
                                    <div class="col-sm" style="padding-right: 5px;padding-left: 3px;">
                                        <div class="tipoContenedor45">
                                            <h2 class="estandar-letra">45</h2>
                                        </div>
                                    </div>
                                    <div class="col-sm" style="padding-right: 3px;padding-left: 5px;">
                                        <div class="tipoContenedorTotal">
                                            <h2 style="font-size: 15px !important;"><b >TOTAL</b></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                        <CCol lg="12">
                            <div class="card-tipo-contenido">
                                <div class="w-100">
                                    <div class="row" style="margin-top:10px;">
                                        <div class="col-sm text-center" style="padding-right: 5px;padding-left: 5px;">
                                            <span style="font-size: 15px !important;"><b >{{item.descarga20}}</b></span>
                                        </div>
                                        <div class="col-sm text-center" style="padding-right: 5px;padding-left: 5px;">
                                            <span style="font-size: 15px !important;"><b >{{item.descarga40}}</b></span>
                                        </div>
                                        <div class="col-sm text-center" style="padding-right: 5px;padding-left: 5px;">
                                            <span style="font-size: 15px !important;"><b >{{item.descarga45}}</b></span>
                                        </div>
                                        <div class="col-sm text-center" style="padding-right: 5px;padding-left: 5px;">
                                            <span style="font-size: 15px !important;"><b >{{item.descarga}}</b></span>
                                        </div>
                                    </div>
                                    <div class="row" style="margin-top:15px;">
                                        <div class="col-sm text-center" style="padding-right: 5px;padding-left: 5px;">
                                            <span style="font-size: 15px !important;"><b >{{item.carga20}}</b></span>
                                        </div>
                                        <div class="col-sm text-center" style="padding-right: 5px;padding-left: 5px;">
                                            <span style="font-size: 15px !important;"><b >{{item.carga40}}</b></span>
                                        </div>
                                        <div class="col-sm text-center" style="padding-right: 5px;padding-left: 5px;">
                                            <span style="font-size: 15px !important;"><b >{{item.carga45}}</b></span>
                                        </div>
                                        <div class="col-sm text-center" style="padding-right: 5px;padding-left: 5px;">
                                            <span style="font-size: 15px !important;"><b >{{item.carga}}</b></span>
                                        </div>
                                    </div>
                                    <div class="row" style="margin-top:15px;">
                                        <div class="col-sm text-center" style="padding-right: 5px;padding-left: 5px;">
                                            <span style="font-size: 15px !important;"><b >{{item.buque20}}</b></span>
                                        </div>
                                        <div class="col-sm text-center" style="padding-right: 5px;padding-left: 5px;">
                                            <span style="font-size: 15px !important;"><b >{{item.buque40}}</b></span>
                                        </div>
                                        <div class="col-sm text-center" style="padding-right: 5px;padding-left: 5px;">
                                            <span style="font-size: 15px !important;"><b >{{item.buque45}}</b></span>
                                        </div>
                                        <div class="col-sm text-center" style="padding-right: 5px;padding-left: 5px;">
                                            <span style="font-size: 15px !important;"><b >{{item.buque}}</b></span>
                                        </div>
                                    </div>
                                    <div class="row" style="margin-top:15px;margin-bottom:10px;">
                                        <div class="col-sm text-center" style="padding-right: 5px;padding-left: 5px;">
                                            <span style="font-size: 15px !important;"><b >{{item.buque_tierra20}}</b></span>
                                        </div>
                                        <div class="col-sm text-center" style="padding-right: 5px;padding-left: 5px;">
                                            <span style="font-size: 15px !important;"><b >{{item.buque_tierra40}}</b></span>
                                        </div>
                                        <div class="col-sm text-center" style="padding-right: 5px;padding-left: 5px;">
                                            <span style="font-size: 15px !important;"><b >{{item.buque_tierra45}}</b></span>
                                        </div>
                                        <div class="col-sm text-center" style="padding-right: 5px;padding-left: 5px;">
                                            <span style="font-size: 15px !important;"><b >{{item.buque_tierra}}</b></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                    </CRow>
                </CCol>
                <CCol lg="12" style="margin-top:10px">
                    <div class="row">
                        <div class="col-lg-3">
                            <div
                                class="card"
                                :style="'border-color: #EC631B !important;height:100%;'"
                            >
                                <div class="card-header"
                                    :style="
                                        'background:' +
                                        ' #EC631B ' +
                                        '!important;width:100%;color:#FFFFFF;'
                                    "
                                    >
                                    <div class="d-flex">
                                        <div class="flex-grow-1"><CIcon name="cil-arrow-bottom"/> <span class="estandar-letra">{{$t('label.DISCHARGE')}}</span></div>
                                        <div class="" 
                                            @click.prevent="cargarLeyenda('descarga')" style="cursor: pointer"
                                            v-c-tooltip="{
                                                content: $t('label.seeDatails'),
                                                placement: 'top-end'
                                            }"
                                        ><CIcon name="cil-info-circle"/></div>
                                    </div>
                                </div>
                                <div class="card-body" v-if="sinInformacion(seriesDischarge)" >
                                    <simpleDonutChart
                                        :legend="legends"
                                        width="100%"
                                        height="auto"
                                        :bandera="bandera"
                                        :title="$t('label.DISCHARGE')"
                                        :series="seriesDischarge" 
                                        :colors="colors"  
                                        :labels="labels"
                                        :legendDisplay="false"
                                    ></simpleDonutChart>
                                </div>
                                <div class="card-body d-flex align-items-center justify-content-center" v-else>
                                    <h6 style="color:#F67272;text-align:center;" class="estandar-letra">{{$t('label.noDataAvailable')}}</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div
                                class="card"
                                :style="'border-color: #1A3760 !important;height:100%;'"
                            >
                                <div class="card-header"
                                    :style="
                                        'background:' +
                                        ' #1A3760 ' +
                                        '!important;width:100%;color:#FFFFFF;'
                                    "
                                    >
                                    <div class="d-flex">
                                        <div class="flex-grow-1"><CIcon name="cil-arrow-top"/> <span class="estandar-letra">{{$t('label.LOAD')}}</span></div>
                                        <div class="" @click.prevent="cargarLeyenda('carga')"  style="cursor: pointer"
                                            v-c-tooltip="{
                                                content: $t('label.seeDatails'),
                                                placement: 'top-end'
                                            }"
                                        ><CIcon name="cil-info-circle"/></div>
                                    </div>
                                </div>
                                <div class="card-body" v-if="sinInformacion(seriesLoad)">
                                    <simpleDonutChart
                                        width="100%"
                                        height="auto"
                                        :bandera="bandera"
                                        :title="$t('label.LOAD')"
                                        :series="seriesLoad" 
                                        :colors="colors"  
                                        :labels="labels"
                                        :legend="legends"
                                        :legendDisplay="false"
                                    ></simpleDonutChart>
                                </div>
                                <div class="card-body d-flex align-items-center justify-content-center" v-else>
                                    <h6 style="color:#F67272;text-align:center;" class="estandar-letra">{{$t('label.noDataAvailable')}}</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div
                                class="card"
                                :style="'border-color: #007A7B !important;height:100%;'"
                            >
                                <div class="card-header"
                                    :style="
                                        'background:' +
                                        ' #007A7B ' +
                                        '!important;width:100%;color:#FFFFFF;'
                                    "
                                    >
                                    <div class="d-flex">
                                        <div class="flex-grow-1"><CIcon name="cil-transfer"/> <span class="estandar-letra">{{$t('label.shipToShip')}}</span></div>
                                        <div class="" @click.prevent="cargarLeyenda('buque_buque')"  style="cursor: pointer"
                                            v-c-tooltip="{
                                                content: $t('label.seeDatails'),
                                                placement: 'top-end'
                                            }"
                                        ><CIcon name="cil-info-circle"/></div>
                                    </div>
                                </div>
                                <div class="card-body" v-if="sinInformacion(seriesBuqueBuque)" >
                                    <simpleDonutChart
                                        width="100%"
                                        height="auto"
                                        :bandera="bandera"
                                        :title="$t('label.shipToShip')"
                                        :series="seriesBuqueBuque" 
                                        :colors="colors"  
                                        :labels="labels"
                                        :legend="legends"
                                        :legendDisplay="false"
                                    ></simpleDonutChart>
                                </div>
                                <div class="card-body d-flex align-items-center justify-content-center" v-else>
                                    <h6 style="color:#F67272;text-align:center;" class="estandar-letra">{{$t('label.noDataAvailable')}}</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div
                                class="card"
                                :style="'border-color: #97CCAB !important;height:100%;'"
                            >
                                <div class="card-header"
                                    :style="
                                        'background:' +
                                        ' #97CCAB ' +
                                        '!important;width:100%;color:#FFFFFF;'
                                    "
                                    >
                                    <div class="d-flex">
                                        <div class="flex-grow-1"><CIcon name="cil-transfer"/> <span class="estandar-letra">{{$t('label.shipLandShip')}}</span></div>
                                        <div class="" @click.prevent="cargarLeyenda('buque_tierra')"  style="cursor: pointer"
                                            v-c-tooltip="{
                                                content: $t('label.seeDatails'),
                                                placement: 'top-end'
                                            }"
                                        ><CIcon name="cil-info-circle"/></div>
                                    </div>
                                </div>
                                <div class="card-body" v-if="sinInformacion(seriesBuqueTierra)">
                                    <simpleDonutChart
                                        width="100%"
                                        height="auto"
                                        :bandera="bandera"
                                        :title="$t('label.shipLandShip')"
                                        :series="seriesBuqueTierra" 
                                        :colors="colors"  
                                        :labels="labels"
                                        :legend="legends"
                                        :legendDisplay="false"
                                    ></simpleDonutChart>
                                </div>
                                <div class="card-body d-flex align-items-center justify-content-center" v-else>
                                    <h6 style="color:#F67272;text-align:center;" class="estandar-letra">{{$t('label.noDataAvailable')}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </CCol>
            </CRow>
        </div>
    </div>
</template>

<script>
    import simpleDonutChart from '../../components/simpleDonutChart';
    //methods
    function routeDoc(item){
        return `${this.$store.getters["connection/getBase"]}${item.Route}`;
    }
    function setAltImg(event){
        event.target.src = '/img/errorimage.jpg';
    }
    function cargarLeyenda(tipo){
        let data = [];
        if(tipo === 'carga'){
            for (let index = 0; index < this.seriesLoad.length; index++) {
                data.push({
                    label: this.labels[index] + ': '+ this.seriesLoad[index],
                    color: this.colors[index]
                })
            }
            this.$store.commit('situacionOperativaActual/asignarModalInformacion', {
                title: this.$t('label.LOAD'),
                iconShow: true,
                nameIcon: "cil-arrow-top",
                data: data,
                show: true,
            })
        }else if(tipo === 'buque_buque'){
            for (let index = 0; index < this.seriesBuqueBuque.length; index++) {
                data.push({
                    label: this.labels[index] + ': '+ this.seriesBuqueBuque[index],
                    color: this.colors[index]
                })
            }
            this.$store.commit('situacionOperativaActual/asignarModalInformacion', {
                title: this.$t('label.shipToShip'),
                iconShow: true,
                nameIcon: "cil-transfer",
                data: data,
                show: true,
            })
        }
        else if(tipo === 'buque_tierra'){
            for (let index = 0; index < this.seriesBuqueTierra.length; index++) {
                data.push({
                    label: this.labels[index] + ': '+ this.seriesBuqueTierra[index],
                    color: this.colors[index]
                })
            }
            this.$store.commit('situacionOperativaActual/asignarModalInformacion', {
                title: this.$t('label.shipLandShip'),
                iconShow: true,
                nameIcon: "cil-transfer",
                data: data,
                show: true,
            })
        }
        else{
            for (let index = 0; index < this.seriesDischarge.length; index++) {
                data.push({
                    label: this.labels[index] + ': '+ this.seriesDischarge[index],
                    color: this.colors[index]
                })
            }
            this.$store.commit('situacionOperativaActual/asignarModalInformacion', {
                title: this.$t('label.DISCHARGE'),
                iconShow: true,
                nameIcon: "cil-arrow-bottom",
                data: data,
                show: true,
            })
        }
    }
    function sinInformacion(array){
        let banderita = false;
        for (let index = 0; index < array.length; index++) {
            if(array[index] !== 0){
                banderita = true;
            }
        }
        return banderita;
    }
    //computed
    function bandera(){
        return this.$store.state.situacionOperativaActual.tabsOperationes === 1 && this.$store.state.situacionOperativaActual.carruselTab == (this.CarruselLimit == 7 ? 6 : this.CarruselLimit == 6 ? 5 : 4);
    }
    function infoArray(){
        let data = [];
        let DataTpCargo = this.TpCargo;
        DataTpCargo.map(async (item) => {
            data.push({
                title: this.$i18n.locale == 'es' ? item.TpCargoName : item.TpCargoNameEn,
                color: item.Color,
                descarga: item.Discharge == 0 ? '-' : item.Discharge,
                descarga20: item.Discharge20 == 0 ? '-' : item.Discharge20,
                descarga40: item.Discharge40 == 0 ? '-' : item.Discharge40,
                descarga45: item.Discharge45 == 0 ? '-' : item.Discharge45,
                carga : item.Load == 0 ? '-' : item.Load,
                carga20: item.Load20 == 0 ? '-' : item.Load20,
                carga40: item.Load40 == 0 ? '-' : item.Load40,
                carga45: item.Load45 == 0 ? '-' : item.Load45,
                buque: item.ShipShip == 0 ? '-' : item.ShipShip,
                buque20: item.ShipShip20 == 0 ? '-' : item.ShipShip20,
                buque40: item.ShipShip40 == 0 ? '-' : item.ShipShip40,
                buque45: item.ShipShip45 == 0 ? '-' : item.ShipShip45,
                buque_tierra: item.ShipLandShip == 0 ? '-' : item.ShipLandShip,
                buque_tierra20: item.ShipLandShip20 == 0 ? '-' : item.ShipLandShip20,
                buque_tierra40: item.ShipLandShip40 == 0 ? '-' : item.ShipLandShip40,
                buque_tierra45: item.ShipLandShip45 == 0 ? '-' : item.ShipLandShip45,
                Route: item.IconRoute
            })
        });
        return data;
    }
    function seriesDischarge(){  
        let data = [];
        let DataTpCargo = this.TpCargo;
        DataTpCargo.map(async (item) => {
            data.push(item.Discharge)
        });
        return data;
    }
    function seriesLoad(){  
        let data = [];
        let DataTpCargo = this.TpCargo;
        DataTpCargo.map(async (item) => {
            data.push(item.Load)
        });
        return data;
    }
    function seriesBuqueBuque(){  
        let data = [];
        let DataTpCargo = this.TpCargo;
        DataTpCargo.map(async (item) => {
            data.push(item.ShipShip)
        });
        return data;
    }
    function seriesBuqueTierra(){  
        let data = [];
        let DataTpCargo = this.TpCargo;
        DataTpCargo.map(async (item) => {
            data.push(item.ShipLandShip)
        });
        return data;
    }
    function colors(){
        let data = [];
        let DataTpCargo = this.TpCargo;
        DataTpCargo.map(async (item) => {
            data.push(item.Color)
        });
        return data;
    }
    function labels(){
        let data = [];
        let DataTpCargo = this.TpCargo;
        DataTpCargo.map(async (item) => {
            if(this.$i18n.locale == 'es'){
                data.push(item.TpCargoName)
            }else{
                data.push(item.TpCargoNameEn)
            }
            
        });
        return data;
    }
    function legends(){
        return {
            show: true,
        }
    }
    export default {
        name:"TipoContenedores",
        props: {
            TpCargo: {
                type: Array,
                default: () => [],
            },
            CarruselLimit: {
                type: Number,
                default: 0,
            }
        },
        methods:{
            routeDoc,
            setAltImg,
            cargarLeyenda,
            sinInformacion
        },
        computed:{
            infoArray,
            seriesDischarge,
            seriesLoad,
            seriesBuqueBuque,
            seriesBuqueTierra,
            colors,
            labels,
            legends,
            bandera,
        },
        components:{
            simpleDonutChart
        }
    }
</script>

<style lang="scss">
    .estandar-letra{
        font-size: 15px !important;
        font-weight: 100 !important;
    }
    .movs-container {
        display: flex;
        flex-direction: column;
    }
    .movs-labels,
    .movs-percent {
        flex: 1;
        font-size:11px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .movs-info {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
    }
    .movs-icon {
        border-radius: 50%;
        padding: 10px 12px;
        color: #fff;
    }
    .bg-descarga {
        background-color: #EC631B;
    }
    .bg-carga {
        background-color: #1A3760;
    }
    .bg-buque {
        background-color: #007A7B;
    }
    .bg-buque-tierra {
        background-color: #97CCAB;
    }
    .card-tipo-contenido{
        border: 1px solid #e1e1e1;
        border-radius: 5px;
    }
    .tipoContenedor20 {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background: #00343F;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin:0px auto;
        padding:3%;

        h2 {
            font-family: sans-serif;
            color: white;
            font-size: 0.7rem;
            font-weight: bold;
            margin-bottom:0px;
        }
    }
    .tipoContenedor40 {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background: #FFBF67;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin:0px auto;
        padding:3%;

        h2 {
            font-family: sans-serif;
            color: white;
            font-size: 0.7rem;
            font-weight: bold;
            margin-bottom:0px;
        }
    }
    .tipoContenedor45 {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background: #D36F4D;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin:0px auto;
        padding:3%;

        h2 {
            font-family: sans-serif;
            color: white;
            font-size: 0.7rem;
            font-weight: bold;
            margin-bottom:0px;
        }
    }
    .tipoContenedorTotal {
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin:0px auto;
        padding:3%;

        h2 {
            font-family: sans-serif;
            font-size: 0.7rem;
            font-weight: bold;
            margin-bottom:0px;
        }
    }
</style>